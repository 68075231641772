import Link from 'next/link'
import Image from 'next/image'
import LogoImg from "@/images/watcher-logo.png";

export default function Logo() {
  return (
    <Link className="inline-flex" href="/" aria-label="Cruip">
      <Image className="max-w-none" src={LogoImg} height={20} priority alt="Stellar" />
    </Link>
  )
}